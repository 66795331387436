.navbar {
    width: 100%;
    height: 80px;
    background-color: black;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


.links {
    margin-right: 50px;
    display: flex;
    align-items: center;
    font-family: 'Urbanist', sans-serif;
}

.links a {
    text-decoration: none;
    color: white;
    margin-left: 20px;
    font-size: 25px;
}




@media (max-width: 385px) {
    .navbar {
        width: 100%;
        height: 30px;
        background-color: black;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .links a {
        text-decoration: none;
        color: white;
        margin-left: 20px;
        font-size: 23px;
    }
}