.benefits {
  margin: 20px auto;
  max-width: 620px; /* Center and limit the width */
  padding: 40px;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.benefits-content {
  display: flex; /* Enable flexbox layout */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  gap: 30px; /* Space between the image and the list */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.benefits-image {
  max-width: 300px; /* Set the maximum width for the image */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Optional: Rounded corners */
  display: block; /* Make the image a block element so it can be centered */
  margin: 0 auto; /* Automatically center the image horizontally */
}


.benefits-list {
  list-style-type: disc; /* Bullet points */
  padding-left: 20px;
  margin: 0;
  color: white; /* Subtle text color */
  font-family: Urbanist, sans-serif; /* Fallback font */
  text-align: left;
  font-size: 15.7px;
}

.benefits-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  font-family: 'Fontspring-DEMO', sans-serif; /* Optional: Custom font */
  color: #333; /* Darker text */
}

.shining-text-ben {
  font-size: 1.5rem; /* Slightly larger text for the title */
  font-weight: bold;
  background: linear-gradient(to right, #87cefa, #5dade2, #4682b4, #87cefa, #5dade2, #4682b4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
  background-size: 300% 300%;
}

@keyframes shine {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.benefit-item {
  margin-bottom: 10px;
  line-height: 1.5; /* Increase line spacing for readability */
}

.benefit-item::marker {
  color: #007bff; /* Custom bullet color (blue) */
  font-size: 1.2em; /* Larger bullets */
}

/* Adjustments for smaller screens (max-width: 440px) */
@media (max-width: 440px) {
  .benefits {
    padding: 20px; /* Reduce padding for smaller screens */
    margin-top: 100px !important;
  }

  .benefits-image {
    display: none; /* Completely hide the image */
  }

  .benefits-content {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center-align content */
    gap: 20px; /* Reduced gap between image and list */
  }

  .benefits-list {
    font-size: 15px !important; /* Make the font slightly bigger for readability */
    padding-left: 15px; /* Reduce left padding */
    max-width: 90%; /* Allow the list to take up more width */
    text-align: center; /* Center-align the text for better aesthetic */
  }
}




@media (max-width: 385px) {
  .benefits {
    margin: 0px auto;
    max-width: 320px; /* Center and limit the width */
    padding: 5px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .benefits-content {
    display: flex; /* Enable flexbox layout */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    gap: 0px; /* Space between the image and the list */
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
  }
  
  .benefits-list {
    list-style-type: disc; /* Bullet points */
    padding-left: 20px;
    margin: 0;
    color: white; /* Subtle text color */
    font-family: Urbanist, sans-serif; /* Fallback font */
    text-align: left;
    font-size: 10.7px;
  }
  
  .benefits-title {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 15px;
    font-family: 'Fontspring-DEMO', sans-serif; /* Optional: Custom font */
    color: #333; /* Darker text */
  }


}
