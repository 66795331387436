.header {
    text-align: center;
    margin-top: 20px;
}

.logo {
    max-width: 150px; /* Adjust size as needed */
    height: auto;
    filter: drop-shadow(0px 0px 1px rgba(0, 4, 19, 19)); /* Outer glow effect */
}
