.cart-header {
  position: absolute; /* Position it relative to the screen */
  top: 10%; /* Adjust this value to control vertical placement */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Offset the element to center it horizontally */
  text-align: center;
  margin: 0; /* Remove any default margins */
  max-width: 600px;
  font-family: 'Urbanist', sans-serif;
  
}

.checkout {
    text-align: center;
    margin-top: -10px;
    margin-left: 635px; /* Add space between cart items and subtotal */
    position: fixed; /* Use absolute positioning */
    width: auto; /* Adapt width based on content */
    max-width: 600px; /* Optional: Limit width for readability */
    top: var(--checkout-top, 0); /* Dynamically controlled by JavaScript */
    left: var(--checkout-left, 50%); /* Dynamically controlled by JavaScript */
    transform: translate(-50%, 0); /* Center horizontally */
    z-index: 1000; /* Ensure visibility over other elements */
    transition: all 0.9s ease;
    font-family: 'Urbanist', sans-serif; /* Smooth transition for position changes */
    background: linear-gradient(to right, #0d47a1, #1976d2, #1e88e5, #1565c0, #0d47a1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s linear infinite;
    background-size: 300% 300%;
    
}

.shining-text-fixedbttns {
    font-size: 0.7rem; /* Adjust as needed */
    font-weight: bold;
    font-family: 'Urbanist', sans-serif;
    text-align: center;
    background: linear-gradient(to right, #fffacd, #ffd700, #b8860b, #fffacd, #ffd700, #b8860b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 6s linear infinite;
    background-size: 300% 300%;
  }
.empty-cart {
  position: absolute; /* Position it relative to the screen */
  top: 30%; /* Adjust this value to control vertical placement */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Offset the element to center it horizontally */
  text-align: center;
  margin: 0; /* Remove any default margins */
  max-width: 600px;
}

  .account-info-message {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
  }
  
  .empty-cart {
    text-align: center;
    margin-top: 20px;
    font-family: 'Urbanist', sans-serif;
  }
  
  h1 {
    text-align: center;
  }
  
  .checkout {
    text-align: center;
  }
.back-to-shop-btn {
    background-color: #6772e5; /* Default button color */
    color: white;
    border: none;
    border-radius: 25px;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
    width: 200px; /* Uniform button size */
    font-family: 'Urbanist', sans-serif;
}


.fixed-buttons {
    position: fixed; /* Use absolute so JavaScript controls its position */
    display: flex;
    margin-left: 30px;
    margin-top: 35px; /* Add spacing between cartItems and buttons */
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px; /* Space between buttons */
    z-index: 1000; /* Ensure visibility above other content */
    transition: all 0.9s ease-in-out; /* Smooth adjustments for position changes */
    
}

.action-button {
    background-color: #6772e5; /* Default button color */
    color: white;
    border: none;
    border-radius: 25px;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
    width: 200px; /* Uniform button size */
}

.action-button:hover {
    background-color: #9D00FF; /* Slightly darker on hover */
}


/* Specific Button Variations */
.stripe-button {
    background-color: #6772e5; /* Stripe's purple color */
}

.crypto-button {
    background-color: #6772e5; /* Green for crypto */
}

.shop-button {
    background-color: #6772e5; /* Blue for continue shopping */
}

.shop-button:hover {
    background-color: #9D00FF;
}

.crypto-button:hover {
    background-color: #9D00FF;
}

/* Cart Container */
.cart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 150px;
    
    
}

@media (min-width: 1024px) {
    .cart {
        margin-left: -300px; /* Offset only for large screens */
        
    
    }
}





/* Cart Items */
.cartItem {
    width: 100%;
    height: 210px;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
    border-radius: 25px;
    margin: 15px;
    font-family: 'Urbanist', sans-serif;
}

/* Cart Item Image */
.cartItem img {
    width: 350px;
    height: auto;
    border-radius: 25px;
}

/* Cart Item Description */
.cartItem .description {
    width: 100%;
    font-size: 18px;
    font-family: 'Urbanist', sans-serif;
    padding: 10px;
}

/* Input for Count Handler */
.countHandler input {
    width: 40px;
    text-align: center;
    font-weight: bolder;
}

/* Checkout Section for Small Screens */
.checkout-buttons {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px; /* Space between buttons */
    margin-top: 20px; /* Add spacing from subtotal */
}

/* Responsive Design */




/* For Tablets and Smaller Screens (Max Width 768px) */
@media (max-width: 768px) {

    .cart{

        
    }
    .fixed-buttons {
        position: static; /* Remove fixed positioning */
        transform: none; /* Reset transform */
        margin-top: 20px;
    }

    .cartItem {
        width: 90%;
        height: auto;
        flex-direction: column;
        padding: 15px;
    }

    .cartItem img {
        width: 100%;
        max-width: 300px;
        margin-bottom: 10px;
    }

    .cartItem .description {
        font-size: 24px;
        text-align: center;
    }

    .action-button {
        width: 180px;
        height: 45px;
        font-size: 14px;
    }
}

/* For Mobile Phones (Max Width 480px) */
@media (max-width: 480px) {
    .cart{
        margin-top: 250px;
    }

    .cart-header{
        font-size: 15px;
    }

    .fixed-buttons {
        position: static; /* Buttons move to natural flow under subtotal */
        transform: none;
        margin-top: 20px;
    }

    .cartItem {
        width: 100%;
        padding: 0;
        margin: 30px 0;
    }

    .cartItem img {
        max-width: 250px;
    }

    .cartItem .description {
        font-size: 20px;
        padding: 5px;
    }

    .action-button {
        width: 150px;
        height: 40px;
        font-size: 10px;
    }
}








@media (max-width: 400px) {
    .cart {
        margin-top: 250px;
    }

    .cart-header {
        font-size: 15px;
    }

    .mobile-buttons {
        display: flex;
        flex-direction: column; /* Stack buttons and subtotal vertically */
        margin-top: 20px; /* Add spacing from products */
        align-items: center; /* Center-align the content */
        gap: 15px; /* Add spacing between buttons */
    }

    .mobile-buttons .checkout {
        margin: 0; /* Reset margin */
        padding: 10px; /* Add padding for better spacing */
        text-align: center; /* Center-align the subtotal text */
        font-size: 18px; /* Adjust font size for readability */
        border-radius: 8px; /* Optional: Rounded corners */
        width: 100%; /* Full width */
        max-width: 300px; /* Limit width for smaller screens */
    }

    .mobile-buttons .action-button {
        width: 90%; /* Make buttons wider for better usability */
        max-width: 300px; /* Optional: Set a maximum width */
        font-size: 14px; /* Adjust font size */
        margin: 10px 0; /* Add spacing between buttons */
        padding: 12px; /* Ensure buttons remain tappable */
    }
}

  
@media (max-width: 440px) {
    .cart {
        margin-top: 250px;
    }

    .cart-header {
        font-size: 15px;
    }

    

    .mobile-buttons {
        display: flex;
        flex-direction: column; /* Stack buttons and subtotal vertically */
        margin-top: 20px; /* Add spacing from products */
        align-items: center; /* Center-align the content */
        gap: 15px; /* Add spacing between buttons */
    }

    

    .mobile-buttons .action-button {
        width: 90%; /* Make buttons wider for better usability */
        max-width: 300px; /* Optional: Set a maximum width */
        font-size: 14px; /* Adjust font size */
        margin: 0px 0; /* Add spacing between buttons */
        padding: 12px; /* Ensure buttons remain tappable */
    }
}

.crypto-dropdown-container {
    position: relative;
  }
  
  .crypto-dropdown {
    position: absolute;
    top: 100%; /* Aligns the dropdown below the button */
    left: 0;
    z-index: 10;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .crypto-dropdown select {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
  }
  
  .crypto-confirm-button {
    width: 100%;
    background-color: #6772e5;
    color: white;
    border: none;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .crypto-confirm-button:hover {
    background-color: #9D00FF;
  }

  