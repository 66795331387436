.speech-bubble2 {
    position: absolute;
    top: 380px; /* Adjust based on button position */
    left: 39.8%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    font-size: 12px;
    color: #333;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeInOut 2s ease-in-out forwards;
    font-family: 'Urbanist', sans-serif;
}

@keyframes fadeInOut {
    0% { opacity: 0; transform: translateY(-10px); }
    10% { opacity: 1; transform: translateY(0); }
    90% { opacity: 1; transform: translateY(0); }
    100% { opacity: 0; transform: translateY(-10px); }
}


@media (max-width: 400px) {
    .speech-bubble2 {
        position: absolute;
        top: 218px; /* Adjust based on button position */
        left: 25.5%;
        transform: translateX(-50%);
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 10px;
        padding: 10px;
        font-size: 12px;
        color: #333;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        animation: fadeInOut 2s ease-in-out forwards;

    }
}

@media (max-width: 440px) {
    .speech-bubble2 {
        position: absolute;
        top: 218px; /* Adjust based on button position */
        left: 25.5%;
        transform: translateX(-50%);
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 10px;
        padding: 10px;
        font-size: 12px;
        color: #333;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        animation: fadeInOut 2s ease-in-out forwards;

    }
}