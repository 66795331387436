.reviews-section {
  padding: 20px;
  max-width: 600px;
  
  text-align: center;
  position: relative;
  background-image: url("../assets/tgchat.png"); /* Background image of Telegram chat */
  background-size: cover;
  background-position: center;
  height: 700px; /* Fixed height for the section */
  overflow: hidden; /* Hide overflow to prevent content from spilling out */
  border-radius: 16px;
}

.reviews-carousel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; /* Center align reviews */
  height: 700px; /* Fixed height for carousel */
  width: 100%;
  position: relative;
  animation: scrollReviews 10s linear infinite; /* Increased duration for smoother scrolling */
  
}

.reviews-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the reviews */
  height: auto; /* Content height will be dynamically set based on the number of reviews */
}

.review-card {
  padding-left: 20px;
  margin: 5px 0;
  background-color: #DCF8C6;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  color: #4CAF50;
  text-align: left; /* Align text to the left */
  font-size: 14px;
  flex-shrink: 0; /* Ensure the reviews do not shrink */
}

@keyframes scrollReviews {
  0% {
    transform: translateY(0); /* Start at the top */
  }
  100% {
    transform: translateY(-100%); /* Scroll up through all reviews */
  }
}

.channel {
  font-weight: bold;
  color: #4CAF50; /* Light green text for channel */
}

.review-text {
  font-family: Arial, sans-serif;
  color: black; /* Black color for the review text */
  font-weight: normal; /* Make review text normal weight */
  margin-top: -10px;
  margin-bottom: 15px;
  padding-right: 20px;
}

@keyframes shine {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 385px) {
  .reviews-section {
    max-width: 900px;
    height: 1000px; /* Adjusted height for smaller screen sizes */
  }

  .reviews-section h2 {
    font-size: 15px !important;
  }

  .review-card {
    font-size: 10px;
    max-width: 180px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #ddd;
    border-radius: 16px;
  }
}
