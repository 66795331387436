.shop {
  background-color: black; /* Set the background to black */
  color: #fff; /* Ensure text is white for contrast */
  min-height: 100vh; /* Ensure the shop covers the full viewport height */
  
}

.welcome-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px; /* Adjust for desired spacing */
    padding: 0px;
  }

  .welcome-text {
    position: absolute; /* Position relative to the .welcome-image container */
    top: 35%; /* Center vertically */
    left: 30%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for proper centering */
    z-index: 1; /* Ensure the text is above the image */
    
  }
  .welcome-image {
    flex: 1; /* Take up equal space within a flex container */
    display: flex; /* Enable flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    max-width: 100%; /* Limit width to container size */
    height: auto; /* Maintain aspect ratio */
    overflow: hidden; /* Prevent image overflow */
    object-fit: contain;
    
}

.benefits-reviews-container {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Adds space between Benefits & Reviews */
  align-items: flex-start; /* Aligns content to the top */
  margin-top: 40px;
}

.benefits-section,
.reviews-section {
  flex: 1; /* Makes both sections take equal width */
  max-width: 48%; /* Adjust width as needed */
}


.title-section {
  display: flex; /* Enable flexbox for consistent layout */
  justify-content: center; /* Center the title horizontally */
  align-items: center; /* Vertically center the title */
  margin: 10px 0; /* Add vertical spacing */
  padding-left: 60px; /* Add padding to move it away from the edge (if necessary) */
}

.explore-title {
  font-size: 2rem; /* Adjust font size as needed */
  font-weight: bold;
  color: white; /* Adjust color to fit your theme */
  font-family: 'Fontspring-DEMO', sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(to right, #add8e6, #87ceeb, #4682b4, #add8e6, #87ceeb, #4682b4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
  background-size: 300% 300%;
  padding: 3px;
}

  
  
  .shining-text {
    font-size: 2.75rem; /* Adjust as needed */
    font-weight: bold;
    font-family: 'Urbanist', sans-serif;
    text-align: left;
    background: linear-gradient(to right, #fffacd, #ffd700, #b8860b, #fffacd, #ffd700, #b8860b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 6s linear infinite;
    background-size: 300% 300%;
    padding: 10px;
  }


  .subtext {
    font-size: 1rem; /* Smaller text size */
    font-weight: normal; /* Regular weight */
    color: white; /* Or another color for the subtext */
    margin-top: 10px; /* Adds some space between the lines */
  }

 /* src/pages/shop/shop.css */
.shining-text-desc {
  font-size: 1rem; /* Adjust as needed */
  font-weight: bold;
  font-family: 'Urbanist', sans-serif;
  text-align: center;
  background: linear-gradient(to right, #add8e6, #87ceeb, #4682b4, #add8e6, #87ceeb, #4682b4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite, scaleAnimation 2s ease-in-out infinite; /* Add scale animation */
  background-size: 300% 300%;
}

/* Define the scale animation */
@keyframes scaleAnimation {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.1); /* Slightly enlarge */
  }
  100% {
      transform: scale(1);
  }
}

  

  .shining-text-addtocart {
    font-size: 0.9rem; /* Adjust as needed */
    font-weight: bold;
    font-family: 'Urbanist', sans-serif;
    text-align: center;
    background: linear-gradient(to right, #add8e6, #87ceeb, #4682b4, #add8e6, #87ceeb, #4682b4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s linear infinite;
    background-size: 300% 300%;

  }
  
  
  
  @keyframes shine {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  

  .shopTitle {
    font-size: 3rem; /* Make the font more prominent */
    color: #333; /* Adjust text color for better contrast */
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.6);
    padding: 40px;
    font-family: 'HolenVintage', sans-serif;
    
  }


.shopTitle .logo {
    position: absolute;
    top: 115px; /* Adjust as needed to position above text */
    left: 50%;
    transform: translateX(-50%);
    width: 150px; /* Adjust size as desired */
    height: auto;
    filter: drop-shadow(0px 0px 1px rgba(0, 4, 19, 19)); /* Outer glow effect */
    border-radius: 50px; /* Optional: smooth the edges slightly */
}

.shopTitle h1 {
    font-size: 24px;
    margin-top: 50px; /* Adjust to position below the logo */
    
}


.products {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr ;
    place-items: center;
    margin-top: -200px; /* Optional: additional spacing */
    margin-bottom: -150px;
    padding-top: calc(10px + var(--logo-height, 120px)); /* Dynamic spacing based on logo size */
    
}

.out-of-stock {
    text-align: center;
    margin: 20px 0;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Fontspring-DEMO', sans-serif;
    letter-spacing: 2px; /* Add spacing between letters */
    margin: 300px;
    margin-bottom: 400px;
  }
  

  .telegram-link {
    font-weight: bold;
    text-decoration: none;
    background: linear-gradient(
      to right,
      #00c6ff, /* Light blue */
      #0072ff, /* Medium blue */
      #00c6ff /* Light blue again for loop effect */
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease;
    padding: 1px;
  }
  
  .telegram-link:hover {
    text-decoration: none;
    background: linear-gradient(
      to right,
      #00f0ff, /* Bright cyan */
      #0055ff, /* Deep blue */
      #00f0ff /* Bright cyan */
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 2px rgba(0, 114, 255, 0.6), 0 0 2px rgba(0, 114, 255, 0.4);
    transition: all 0.3s ease;
  }
  
  

    

  
  .out-of-stock .sold-out-text {
    color: white; /* Text color should contrast with the gradient */
    font-weight: bold;
    background: linear-gradient(
    to right,
    #ff4e50, /* Bright red */
    #ff7b7b, /* Soft pinkish red */ /* Pale red for a smoother transition */
    #ff7b7b, /* Soft pinkish red */
    #ff4e50  /* Bright red again for loop effect */

);

    background-clip: text; /* Clip the gradient to the text */
    -webkit-background-clip: text; /* Webkit-specific clipping */
    -webkit-text-fill-color: transparent; /* Makes the gradient show through the text */
    animation: soldOutGradient 6s linear infinite; /* Animates the gradient */
    background-size: 300% 100%; /* Adjust background size for smooth animation */
    padding: 2px;
  }
  
  /* Optional animation for a moving gradient effect */
  @keyframes soldOutGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  

 
  

@media (max-width: 1000px) {
    .products {
        grid-template-columns: 1fr; /* 2 columns for tablets */
    }
}








 

.aged-accs-section {
  display: flex; /* Enable flexbox for layout consistency */
  flex-direction: column; /* Ensure content flows vertically */
  justify-content: flex-start; /* Align content to the left */
  align-items: flex-start; /* Align items to the left */
  margin: 50px 0; /* Add vertical spacing */
   /* Add padding to align with the title section */
}

.aged-accs-title {
  font-size: 2rem; /* Same font size as explore-title */
  font-weight: bold; /* Make it bold for emphasis */
  color: white; /* Match the theme */
  font-family: 'Fontspring-DEMO', sans-serif; /* Ensure font consistency */
  margin-bottom: 20px; /* Add spacing below the title */
  padding-left: 60px;
  text-align: center;
}



.product {
    border-radius: 15px;
    width: 300px; /* Restored original width */
    height: 350px; /* Restored original height */
    margin: 100px; /* Restored original margin */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product-container {
    position: absolute;
}

.product img {
    width: 400px; /* Restored original image width */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.9);
    z-index: 3; /* Set a higher z-index */
    position: relative; /* Ensure positioning context for z-index */
    height: auto; /* Maintain aspect ratio */
}

.product .description {
    text-align: center;
    font-family: 'Urbanist', sans-serif;
    
}

.product:hover {
    transition: 0.3s ease-in;
    cursor: pointer;
}

.addToCartBttn {
    background-color: #000000; /* Gold background color */
    color: #fff; /* White text color */
    font-size: 0.7rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    text-transform: uppercase;
    transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition */
    font-family: 'Urbanist', sans-serif;
  }
  
  /* Glow effect intensifies on hover */
  .addToCartBttn:hover {
    transform: scale(1.1); /* Slightly enlarge button, less than 1.1 */
    
}

  

  

  .product-container {
    position: relative; /* Make the container relative to allow absolute positioning of speech-bubble */
  }




.peeking-character {
  position: absolute;
  top: 150px;
  left: 25%;
  transform: translateX(-50%) rotate(30deg); /* Adjust rotation */
  width: 100px;
  z-index: 1;
  transition: top 0.5s ease-in-out, transform 0.5s ease;
}

.product-container:hover .peeking-character {
  top: 100px;
  transform: translateX(-50%) rotate(15deg);
}

.speech-bubble {
  position: absolute;
  font-family: 'Urbanist', sans-serif;
  top: 200px; /* Adjust to position near character */
  left: 43%; /* Center the speech bubble horizontally */
  margin-left: 10px; /* Space between character and speech bubble */
  background-color: black;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  color: black;
  opacity: 0;
  transition: top 0.5s ease-in-out, opacity 0.5s ease;
  z-index: 1;
  font-weight: bold;
  background: linear-gradient(to right, #0d47a1, #1976d2, #1e88e5, #1565c0, #0d47a1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
  background-size: 300% 300%;
  color: transparent; /* Ensure text color is fully transparent for gradient */
}

.product-container:hover .speech-bubble {
  opacity: 1;
  top: 110px; /* Adjust upward to follow character */
  left: 40%; /* Position bubble directly next to the character */
  margin-left: 10px; /* Space between character and bubble */
  transform: translateX(0); /* Center the bubble relative to the peeking character */
}



.speech-bubble-shiny {
    position: absolute;
    font-family: 'Urbanist', sans-serif;
    
    background-color: black;
    padding: 5px 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    color: black;
    opacity: 0;
    transition: top 0.5s ease-in-out, opacity 0.5s ease;
    z-index: 1;
    transform: translateX(-50%);
    background: linear-gradient(to right, #fffacd, #ffd700, #b8860b, #fffacd, #ffd700, #b8860b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 6s linear infinite;
    background-size: 300% 300%;
}

.shining-text-speech {
    font-size: 12px; /* Adjust as needed */
    font-weight: bold;
    font-family: 'Urbanist', sans-serif;
    text-align: center;
    background: linear-gradient(to right, #fffacd, #ffd700, #b8860b, #fffacd, #ffd700, #b8860b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 6s linear infinite;
    background-size: 300% 300%;
  }



.speech-bubble::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 40%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: black;
    
}

@font-face {
    font-family: 'HolenVintage'; /* Name of the font */
    src: url('../../assets/fonts/HolenVintage.otf') format('truetype');
    font-weight: normal; /* Optional: Define weight */
    font-style: normal; /* Optional: Define style */
}

@font-face {
    font-family: 'Fontspring-DEMO'; /* Name of the font */
    src: url('../../assets/fonts/Fontspring-DEMO.otf') format('truetype');
    font-weight: normal; /* Optional: Define weight */
    font-style: normal; /* Optional: Define style */
}
@font-face {
    font-family: 'Urbanist-Italic'; /* Name of the font */
    src: url('../../assets/fonts/Urbanist-Italic.otf') format('truetype');
    font-weight: normal; /* Optional: Define weight */
    font-style: normal; /* Optional: Define style */
}
@font-face {
    font-family: 'Urbanist'; /* Name of the font */
    src: url('../../assets/fonts/Urbanist.otf') format('truetype');
    font-weight: normal; /* Optional: Define weight */
    font-style: normal; /* Optional: Define style */
}



.benefits-reviews-container {
  display: flex;
  justify-content: center; /* Center the sections horizontally */
  align-items: center; /* Align items in the center vertically */
  gap: 10px; /* Reduced gap between Benefits & Reviews */
  flex-wrap: wrap; /* Allows wrapping when the screen is too small */
  max-width: 80%; /* Optionally limit the width of the container */
  margin: 0 auto; /* Center the container within the parent */
}

.benefits-wrapper,
.reviews-wrapper {
  flex: 1;
  min-width: 250px; /* Make the sections smaller */
  max-width: 45%; /* Prevent them from stretching too wide */
  padding: 20px; /* Add some space inside the sections */
  box-sizing: border-box; /* Ensure padding doesn't affect the width */
}

.shining-text-rev {
  font-size: 1.5rem; /* Slightly larger text for the title */
  font-weight: bold;
  margin-top: 135px;
  background: linear-gradient(to right, #87cefa, #5dade2, #4682b4, #87cefa, #5dade2, #4682b4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
  background-size: 300% 300%;
  font-size: 24px;
font-weight: bold;

    font-family: 'Fontspring-DEMO', sans-serif; /* Optional: Custom font */
}

/* On smaller screens, stack them vertically */
@media (max-width: 1300px) {
  .benefits-reviews-container {
    flex-direction: column;
    align-items: center;
  }
}


@media (max-width: 385px) {
  .welcome-image {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9; /* Fixed aspect ratio of 16:9 */
    object-fit: contain; /* Ensures the image fits inside its container */
    margin: 0 auto; /* Center horizontally */
    max-width: 100% !important;
  }

  .welcome-section {
    display: flex;
    justify-content: center; /* Center the section */
    align-items: center;
    padding: 0;
  }
  .welcome-text {
    position: absolute; /* Position relative to the .welcome-image container */
    top: 38%; /* Center vertically */
    left: 40%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for proper centering */
    z-index: 1; /* Ensure the text is above the image */
    
  }
  .shining-text {
    font-size: 0.75rem; /* Adjust as needed */
    font-weight: bold;
    font-family: 'Urbanist', sans-serif;
    text-align: left;
    background: linear-gradient(to right, #fffacd, #ffd700, #b8860b, #fffacd, #ffd700, #b8860b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 6s linear infinite;
    background-size: 300% 300%;
    padding: 10px;
  }
  .title-section {
    align-items: center; /* Vertically center the title */
  }
  .explore-title {
    font-size: 15px ; /* Adjust font size as needed */
    font-weight: bold;
    color: white; /* Adjust color to fit your theme */
    font-family: 'Fontspring-DEMO', sans-serif;
    text-align: center ;
    display: flex;
    justify-content: center;
    align-items: center ;
    font-weight: bold;
    text-align: center;
    background: linear-gradient(to right, #add8e6, #87ceeb, #4682b4, #add8e6, #87ceeb, #4682b4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s linear infinite;
    background-size: 300% 300%;
    padding: 3px;
  }
  .products {
    width: 80% !important;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    margin-top: -200px; /* Optional: additional spacing */
    padding-top: calc(10px + var(--logo-height, 120px)); /* Dynamic spacing based on logo size */
    
}
.product img {
  width: 300px; /* Restored original image width */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.9);
  z-index: 3; /* Set a higher z-index */
  position: relative; /* Ensure positioning context for z-index */
  height: auto; /* Maintain aspect ratio */
}
.peeking-character {
  position: absolute;
  top: 250px;
  left: 25%;
  transform: translateX(-50%) rotate(30deg); /* Adjust rotation */
  width: 100px;
  z-index: 1;
  transition: top 0.5s ease-in-out, transform 0.5s ease;
}
.product {
  border-radius: 15px;
  width: 30%; /* Restored original width */
  height: 350px; /* Restored original height */
  margin-bottom: -100px !important; /* Restored original margin */
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.product-container {
  left: 0%;
 
}
.shining-text-rev {
  font-size: 15px; /* Slightly larger text for the title */
  font-weight: bold;
  background: linear-gradient(to right, #87cefa, #5dade2, #4682b4, #87cefa, #5dade2, #4682b4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
  background-size: 300% 300%;
  
font-weight: bold;

    font-family: 'Fontspring-DEMO', sans-serif; /* Optional: Custom font */
}
}




@media (max-width: 386px) {
  .product img {
    width: 300px; /* Restored original image width */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.9);
    z-index: 3; /* Set a higher z-index */
    position: relative; /* Ensure positioning context for z-index */
    height: auto; /* Maintain aspect ratio */
  }
}


@media (max-width: 440px) {
  .welcome-image {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9; /* Fixed aspect ratio of 16:9 */
    object-fit: contain; /* Ensures the image fits inside its container */
    margin: 0 auto; /* Center horizontally */
    max-width: 100% !important;
  }

  .welcome-section {
    display: flex;
    justify-content: center; /* Center the section */
    align-items: center;
    padding: 0;
  }
  .welcome-text {
    position: absolute; /* Position relative to the .welcome-image container */
    top: 28%; /* Center vertically */
    left: 40%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for proper centering */
    z-index: 1; /* Ensure the text is above the image */
    
  }
  .shining-text {
    font-size: 0.75rem; /* Adjust as needed */
    font-weight: bold;
    font-family: 'Urbanist', sans-serif;
    text-align: left;
    background: linear-gradient(to right, #fffacd, #ffd700, #b8860b, #fffacd, #ffd700, #b8860b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 6s linear infinite;
    background-size: 300% 300%;
    padding: 10px;
  }
  .title-section {
    
   
    align-items: center; /* Vertically center the title */
   
  }
  .explore-title {
    font-size: 15px ; /* Adjust font size as needed */
    font-weight: bold;
    color: white; /* Adjust color to fit your theme */
    font-family: 'Fontspring-DEMO', sans-serif;
    text-align: center ;
    display: flex;
    justify-content: center;
    align-items: center ;
    font-weight: bold;
    text-align: center;
    background: linear-gradient(to right, #add8e6, #87ceeb, #4682b4, #add8e6, #87ceeb, #4682b4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s linear infinite;
    background-size: 300% 300%;
    padding: 3px;
  }
  .products {
    width: 80% !important;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    margin-top: -200px; /* Optional: additional spacing */
    padding-top: calc(10px + var(--logo-height, 120px)); /* Dynamic spacing based on logo size */
    
}

.peeking-character {
  position: absolute;
  top: 250px;
  left: 25%;
  transform: translateX(-50%) rotate(30deg); /* Adjust rotation */
  width: 100px;
  z-index: 1;
  transition: top 0.5s ease-in-out, transform 0.5s ease;
}
.product {
  border-radius: 15px;
  width: 35%; /* Restored original width */
  height: 350px; /* Restored original height */
  margin-bottom: -100px !important; /* Restored original margin */
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.product-container {
  left: 0%;
 
}
.shining-text-rev {
  font-size: 15px; /* Slightly larger text for the title */
  font-weight: bold;
  background: linear-gradient(to right, #87cefa, #5dade2, #4682b4, #87cefa, #5dade2, #4682b4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
  background-size: 300% 300%;
  
font-weight: bold;

    font-family: 'Fontspring-DEMO', sans-serif; /* Optional: Custom font */
}
}






