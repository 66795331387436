/* src/contact.css */
.contact {
background-color: white;
}

.contact-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    color: white;
    text-align: center;
}

.telegram-button {
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    background-color: #0088cc; /* Telegram color */
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.telegram-button:hover {
    background-color: #006699;
}
