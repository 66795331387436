
  

  
  .cartItem .description {
    flex: 1;
  }
  
  .cartItem .description p {
    margin: 5px 0;
  }
  
  .cartItem .description p:first-of-type {
    font-size: 1.2rem;
    font-weight: bold;
    background: linear-gradient(to right, #0d47a1, #1976d2, #1e88e5, #1565c0, #0d47a1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s linear infinite;
    background-size: 300% 300%;
  }
  
  .cartItem .countHandler {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .cartItem .countHandler button {
    padding: 5px 10px;
    background-color: #1976d2;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
    
  }
  
  .cartItem .countHandler button:hover {
    background-color: #0d47a1;
    
  }
  
  .cartItem .countHandler input {
    width: 40px;
    text-align: center;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 3px;
  }
  


  @media (max-width: 440px) {
  .cartItem {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center all items */
  }

  .cartItem .countHandler {
    display: flex;
    justify-content: center; /* Center the countHandler horizontally */
    align-items: center; /* Center the countHandler vertically */
    width: 100%;
    margin-top: 10px;
  }

  .cartItem .countHandler button,
  .cartItem .countHandler input {
    max-width: 50px;
    margin: 0 5px;
  }
}
