.footer {
    width: 100%;
    background-color: black;
    color: white;
    text-align: center;
    padding: 20px 0;
    position: relative;
    bottom: 0;
    margin-top: 700px;
    font-family: 'Urbanist', sans-serif;
}

.footer-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px; /* Adds spacing between links and copyright */
    font-family: 'Urbanist', sans-serif;
}

.footer-links a {
    text-decoration: none;
    color: white;
    font-size: 20px;
}

.footer-links a:hover {
    text-decoration: underline;
}

.footer-copy {
    font-size: 14px;
    margin: 0;
    opacity: 0.7; /* Slightly dim text for subtlety */
}


@media (max-width: 400px) {
    .footer {
        padding: 10px 0; /* Further reduce padding */
        margin-top: 300px; /* Further adjust spacing above footer */
    }

    .footer-links {
        gap: 10px; /* Even less space between links */
        flex-direction: row; /* Stack links vertically */
    }

    .footer-links a {
        font-size: 14px; /* Smaller font size for links */
    }

    .footer-copy {
        font-size: 10px; /* Smallest copyright text */
    }
}


@media (max-width: 440px) {
    .footer {
        padding: 10px 0; /* Further reduce padding */
        margin-top: 600px;
        width: 100%;
    }

    .footer-links {
        gap: 10px; /* Even less space between links */
        flex-direction: row; /* Stack links vertically */
    }

    .footer-links a {
        font-size: 14px; /* Smaller font size for links */
    }

    .footer-copy {
        font-size: 10px; /* Smallest copyright text */
    }
}