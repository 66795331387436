/* src/pages/aged/aged.css */
.aged {
    margin-top: 40px;
    background-color: black; /* Set the background to black */
    color: #fff; /* Ensure text is white for contrast */
    min-height: 100vh; /* Ensure the page covers the full viewport height */
    padding: 20px; 
    font-size: 1.5rem; /* Adjust as needed */
    font-weight: bold;
    font-family: 'Urbanist', sans-serif;
    text-align: center;
    background: linear-gradient(to right, #add8e6, #87ceeb, #4682b4, #add8e6, #87ceeb, #4682b4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s linear infinite;
    background-size: 300% 300%; /* Add some padding to make the content not touch the edges */
}

.aged-character {
    width: 300px; /* Set the width */
    height: auto; /* Maintain aspect ratio */
    margin-top: 20px; /* Optional: Adds spacing above the image */
}

.aged-description {
    font-size: 1.2em;
    margin-top: 0px;
    max-width: 800px; /* Ensure the description doesn't stretch too wide */
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6; /* Add some spacing between lines for readability */
    color: #ddd; /* Light grey color for the text */
}

.aged-dm {
    font-size: 1.2em;
    margin-top: 20px;
    max-width: 800px; /* Ensure the description doesn't stretch too wide */
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(to right, #fffacd, #ffd700, #b8860b, #fffacd, #ffd700, #b8860b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 6s linear infinite, scaleAnimation 2s ease-in-out infinite; /* Add scale animation */
    background-size: 300% 300%;
}

/* Define the scale animation */
@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1); /* Slightly enlarge */
    }
    100% {
        transform: scale(1);
    }
}
